/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { AuthProvider } from "react-oidc-context";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import SessionProvider from "./Context/Session/SessionProvider";
import { store } from "./redux/store";
import App from "./App";
import { WebStorageStateStore } from "oidc-client-ts";

const oidcConfig = {
    authority: process.env.REACT_APP_COGNITO_AUTHORITY,
    client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_COGNITO_REDIRECT_URI,
    response_type: "code",
    scope: process.env.REACT_APP_COGNITO_SCOPE,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistProvider>
            <ThemeProvider hideSettingsCard>
                <AuthProvider {...oidcConfig}>
                    <SessionProvider>
                        <App />
                    </SessionProvider>
                </AuthProvider>
            </ThemeProvider>
        </PersistProvider>
    </Provider>
);
